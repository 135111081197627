.three-sprite{
	display: none;
}

.socials__icon{
	width: 30px;
	height: 30px;
}

.preloader{
	position: fixed;
	display: flex;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: 100;
	// background: url('') center no-repeat;
	background-color: $green-light;
	opacity: .5;
	transition: 1s all;
	opacity: 1;
	visibility: visible;

	&.done{
		opacity: 0;
		visibility: hidden;
	}
}

.round1,.round1:before,.round1:after{
	border: 3px solid transparent;
	border-radius: 50%;
}

.round1{
	margin: auto;
	width: 150px;
	height: 150px;
	position: relative;
	// border-top-color: #bac900;
	border-top-color: #fff;
	animation: spin 1s infinite linear;
}
.round1:before,.round1:after{
	content: "";
	position: absolute;
}

.round1:before{
	top: 10px;
	right: 10px;
	left: 10px;
	bottom: 10px;
	// border-top-color: #00abd4;
	border-top-color: #fff;
	animation: spin 2s infinite linear;
}

.round1:after{
	top: 23px;
	right: 23px;
	left: 23px;
	bottom: 23px;
	// border-top-color: #ff1400;
	border-top-color: #fff;
	animation: spin 3s infinite linear;
}

#load-perc{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
	font-size: 20px;
	color: #fff;
}

@keyframes spin{
	from{
		transform: rotate(0deg);
	}
	to{
		transform: rotate(360deg);
	}
}

.wrapper.welcome{
	background: url("/assets/welcome.jpg") no-repeat;
	background-position: center top;
	background-size: cover;
	display: flex;
	flex-direction: column;
    justify-content: space-between;
    align-items: center;
	    position: relative;
	    overflow: hidden;
}

.welcome .container{
	display: flex;
}

#parallax{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.parallax__layer{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    will-change: transform;
}
.parallax__image{
	position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.parallax__image_1{
	top: 5%;
}
.parallax__image_2{
	top: 50%;
}
.parallax__image_3{
	top: 40%;
    left: 30%;
}.parallax__image_4{
	top: 10%;
}
.parallax__image_5{
	top: 23%;
}
.parallax__image_6{
	top: 13%;
}

.welcome .header{
	width: 100%;
	display: flex;
	justify-content: flex-end;
	position: relative;
    height: 70px;
    padding-top: 24px;
}


.auth-button-link{
	display: none;
	color: #fff;
	padding: 11px 28px;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: .3s;

    &:hover{
    	background-color: #fff;
    	color: #00bfa5;
    }
	
}


.welcome .auth-button{
	position: absolute;
    margin-top: 15px;
	line-height: 1;
	top: 24px;
	right: 20px;
	transition: .3s;
	font-family: roboto-medium;
	position: relative;
	top: auto;
}

.user{
	display: flex;
    flex-direction: column;
}

.plate{
	// width: 380px;
	// width: 440px;
	width: 100%;
	// height: 395px;
	// height: 349px;
	// background: rgba(255, 255, 255, 0.8);
	background: #1d1d1d;
    opacity: .8;
	border-radius: 5px 5px 0 0;
	display: flex;
    flex-direction: column;
    justify-content: space-between;

    color: #c6e2ff;
    animation: neon .5s ease-in-out infinite alternate;
}

@keyframes neon {
  from {
    box-shadow:
    0 0 6px rgba(202,228,225,0.92),
    0 0 30px rgba(202,228,225,0.34),
    0 0 12px rgba(0,191,129,0.52),
    0 0 21px rgba(0,191,129,0.92),
    0 0 34px rgba(0,191,129,0.78),
    0 0 54px rgba(0,191,129,0.92);
  }
  to {
    box-shadow:
    0 0 6px rgba(202,228,225,0.98),
    0 0 30px rgba(202,228,225,0.42),
    0 0 12px rgba(0,191,129,0.58),
    0 0 22px rgba(0,191,129,0.84),
    0 0 38px rgba(0,191,129,0.88),
    0 0 60px rgba(0,191,129,1);
  }
}

.plate__name{
	text-align: center;
    // margin-top: -25px;
    margin-top: 10px;
}

.plate__title{
	text-align: center;
    // margin-top: -40px;
    // color: $grey;
    color: $blue;
}

.title-line{
	font-family: roboto-regular;
}

.photo{
	display: flex;
}

svg.photo {
	width: 140px;
    height: 140px;
    border-radius: 50%;
    background: red;
    position: relative;
    margin: 20px auto 15px;

    g {
    	fill: #1d1d1d;
    }
}

.main-photo{
	max-width: 140px;
	max-height: 140px;
	border-radius: 50%;
	margin: auto;
	// margin-top: 4%;
	margin-top: 25px;
    margin-bottom: 15px;
    background: red;

}

.name-line{
	font-size: 28px;
	font-family: roboto-medium;
	line-height: 1.2;
	// color: $grey;
	color: $blue;
}

.socials__welcome{
	margin-top: 30px;
}

.socials__list{
	display: flex;
    justify-content: center;
}

.socials__item{
	margin-left: 15px;
	margin-right: 15px;
}

.socials__icon{
	vertical-align: middle;
	fill: rgba(0, 191, 165, .5);
	transition: .2s;
	&:hover{
		fill: rgb(0, 191, 165);
	}
}

nav.nav{
	position: relative;
    transform: translateY(100%);
}

.nav__list{
	display: flex;
}

.nav__item{
	flex: 1 1 50%;
	font-size: 16px;
	// background-color: $green-light;
	// background-color: #00bf81;
	background-color: $black;
	opacity: 0.85;
	// background: rgba(0,191,165,.85);
	transition: .2s;
	border-right: 1px solid $green;
	&:hover{
		// background-color: $green-dark;
		// background: rgba(0,150,136,.85);
		background: #007162;
	}
	
	&:first-child{
		border-radius: 0 0 0 5px;
	}
	
	&:last-child{
		border-radius: 0 0 5px 0;
		border-right: none;
	}
}

.nav__link{
	color: #fff;
	font-family: roboto-medium;
	display: block;
	width: 100%;
	padding-top: 12px;
	padding-bottom: 12px;
	text-align: center;
}

.footer-line{
	font-size: 16px;
	color: #fff;
	margin-bottom: 30px;
	text-align: center;
	font-family: roboto-regular;
	margin-top: -25px;
}

	.welcome:after{
		content: "";
		display: block;
		height: 44px;
		z-index: 0;
	}
	.footer-line{
		margin-bottom: 0;
	    margin-top: -44px;
	    height: 44px;
	    position: relative;
	    z-index: 10;
	}

.auth__title{
	font-size: 35px;
	text-align: center;
	font-family: 'bernier-regular';
	color: $grey;
	margin: 32px 0;
}

.auth__word{
	position: relative;
	&:before{
		display: block;
		content: "";
		width: 22px;
		height: 5px;
		background-color: #d1d2d2;
		position: absolute;
		top: 47%;
		// left: 13%;
		left: -52px;
	}

	&:after{
		@extend .auth__word:before;
		left: auto;
		// right: 13%;
		right: -52px;
	}

}


.order__form-block{
	display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.auth__icon{
	width: 45px;
    height: 45px;
    background-color: #f0efe9;
	
	&.auth__man{
		position: relative;
		border-radius: 3px 0 0 3px;

		&:after{
			display: block;
			content: "";
			position: absolute;
			width: 13px;
			height: 14px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			// background-image: url('../svg/welcome/login.svg');
			background-position:  center center;
			background-repeat: no-repeat;
			background-size: cover;
		}
	}

	&.auth__password{
		position: relative;
		border-radius: 3px 0 0 3px;

		&:after{
			@extend .auth__man:after;
				// background-image: url('../svg/welcome/password.svg');
				width: 16px;
				height: 15px;
		}
	}
}

.order__auth-line{
	width: 295px;
    height: 45px;
    background: #fff;
    font-size: 16px;
    font-family: 'roboto-regular';
    padding: 20px 12px;
    color: $black;
    border: none;
    border-radius: 0 3px 3px 0;
}

.auth__checkbox{
	width: 120px;
	display: flex;
	margin: auto;
}

.auth__checkbox-elem{
	position: absolute;
    top: 0;
    left: -9999px;
}
.auth__checkbox-title{
	font-family: 'roboto-regular';
	color: $grey;
}

.auth__checkbox-fake{
	width: 20px;
	height: 20px;
	position: relative;
	transition: .3s;
	background: $green-light;
	border-radius: 2px;
	margin-right: 11px;

	&:after{
		content: "";
	    position: absolute;
	    display: block;
	    width: 0;
	    height: 0;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    transition: .3s;
	}
}

.auth__checkbox-elem:checked{
	& + .auth__checkbox-fake{
		background: $green-light;
		&:after{
			width: 13px;
			height: 10px;
			background: transparent;
			// background-image: url('../svg/welcome/check.svg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}
	}
}

.auth__checkbox,.auth__radio{
	cursor: pointer;
}

.order__auth-row:last-child{
	display: flex;
	flex-wrap: wrap;
}

.auth-row-title{
	font-size: 16px;
	font-family: 'roboto-bold';
	color: $grey;
	flex-basis: 100%;
	text-align: center;
}

.auth__radio-title{
 	color:	$grey;
 	font-family: 'roboto-regular';
}


.auth__radio-elem{
	position: absolute;
    top: 0;
    left: -9999px;
}

.auth__radio-fake{
	width: 20px;
	height: 20px;
	border-radius: 50%;
	border: 1px solid #cdcdcd;
	background: #fff;
	position: relative;
	transition: .3s;
	margin-right: 10px;

	&:after{
		content: "";
	    position: absolute;
	    display: block;
	    width: 0;
	    height: 0;
	    top: 50%;
	    left: 50%;
	    transform: translate(-50%, -50%);
	    transition: .3s;
	}
}

.auth__radio-elem:checked{
	& + .auth__radio-fake{
		&:after{
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background: $green-light;
		}
	}
}

.auth__radio-elem:checked + .auth__radio-fake + .auth__radio-title{
	color: $green-light;
}

.auth-row-choise{
	display: flex;
	//margin: auto;
	width: 100%;
	justify-content: center;
}

.auth__radio{
	//width: 110px;
	display: flex;
	margin: 0 30px;
}

.plate2 .nav__item{
	flex: 1 1 50%;
}

.auth__form{
	    margin-top: -25px;
}



.auth-row-title{
	margin: 8px 0;
}

.flipper-block{
	// width: 380px;
	width: 440px;
	height: 349px;
	position: relative;
	margin: 0 auto;

	perspective: 1000px;
	transition: transform 1s;
	transform-style: preserve-3d;
	-webkit-transform-origin: 50% 50%;

	// &:before{
	// 	position: absolute;
	//     display: block;
	//     content: "";
	//     width: 56px;
	//     height: 42px;
	//     background: url("/assets/arrow.png") center center no-repeat;
	//     top: -120%;
	//     left: 0;
	// }
}

.flipper-link {
	width: 60px;
    height: 60px;
    position: absolute;
    top: -50px;
    right: -125px;
    fill: $green;
    transform: scale(-1, 1);
    transition: 1s;
}

.arrow_turned {
	transform: scale(1, 1);
}

.flipper-arrow {
	width: 100%;
	height: 100%;
}

// .flipper-arrow {
// 	width: 60px;
//     height: 60px;
//     position: absolute;
//     top: -50px;
//     right: -125px;
//     fill: $green;
//     transform: scale(-1, 1);
    
// }

.flipper-link path{
	transition: .3s;
}

.flipper-link:hover {
	.flipper-arrow path {
		fill: #5992ad;
	}
}

// svg.flipper-arrow path{
// 	filter: url(#neon);
// }

.front, .back{
	position: absolute;
	backface-visibility: hidden;
	transition: 1s;
}

.back{
    transform: rotateY( 180deg );
}

.flipper-block.flipped .front {
	transform: rotateY( 180deg );
}
.flipper-block.flipped .back {
	transform: rotateY( 360deg );
}

// .flipper-block.flipped {
//     transform: rotateY( -180deg );
// }

// .flipper-block.flipped .plate1 .nav{
//     backface-visibility: hidden;
// }

.plate2 {
	.user__info {
		margin: 20px 0 50px 0;
    	padding: 0 30px;
	}

	.user__info-text {
		margin-top: 10px;
    	display: inline-block;
    	font-size: 1.2em;
	}
}


@media screen and (max-width: 1200px){
	.welcome .header{
		justify-content: flex-end;
	    padding: 10px 25px 0 0;
	    margin-bottom: 25px;
	    padding-top: 24px;
	}

	.welcome .auth-button{
		position: relative;
		top: auto;
		right: auto;
	}
	.welcome .container{
		max-width: 768px;
		justify-content: center;
		margin-bottom: 30px;
	}

	// .plate{
	// 	width: 348px;
	// }
}
@media screen and (max-width: 768px){
	.footer__top .container{
		max-height: 768px;
	}
	.footer__subslogan{
		text-align: center;
		padding-top: 15px;
	}
}

@media screen and (max-width: 695px){
	.auth-button-link {
		display: inline-block;
	}

	.flipper-link {
		display: none;
	}
}

@media screen and (max-width: 600px){
	.wrapper.welcome{
	    height: 100%;
	    position: relative;
	    overflow: hidden;
	}

	.welcome .header{
		justify-content: center;
		padding: 0;
		margin-bottom: 0;

		padding-top: 10px;
    	margin-bottom: 25px;
	}
	
	.welcome .auth-button{
		position: relative;
	    top: auto;
	    right: auto;
	   	height: 46px;
	}

	.welcome .container{
		max-width: 320px;
		margin-bottom: 40px;
	}

	.welcome:after{
		content: "";
		display: block;
		height: 46px;
		z-index: 0;
	}

	.footer-line{
		margin-bottom: 0;
	    margin-top: -46px;
	    height: 46px;
	    position: relative;
	    z-index: 10;
	}

	.welcome .container{
		width: auto;
	}

	// .flipper-block{
	// 	width: 348px;
	// }
	.welcome .plate{
		width: 320px;
	}
	
}

@media screen and (max-width: 360px){
	.flipper-block{
		width: 300px;
	}
	
	.flipper-block .plate{
		width: 300px;
	}

	.plate2 .auth__word{
		font-size: 30px;
	}

	.order__auth-line{
		width: 230px;
	}

}
