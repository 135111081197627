$green-light: #00bfa5;
$green-dark: #009688;
$grey: #455a64;
$black: #373e42;
$offer: #6da3bd;
$blue: #5992ad;
$black: #1d1d1d;
$green: #00bf81;


@import "normalize.scss";
@import "adjustment.scss";
@import "cvcard.scss";
@import "fonts.scss";
