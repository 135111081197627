html,body{
	width: 100%;
	height: 100%;
}

body{
	line-height: 1.42;
	font-size: 16px;
}

*{
	box-sizing: border-box;
}

p{
	padding: 0;
	margin: 0;
}

a{
	text-decoration: none;
}

ul{
	padding: 0;
	margin: 0;
	li{
		list-style: none;
	}
}

%clearfix{
	&:after, &:before{
		content: "";
		display: block;
		clear: both;
		width: 100%;
	}
}

.wrapper{
	position: relative;
	min-height: 100%;
	overflow: hidden;
}

.container{
	max-width: 1200px;
	margin: 0 auto;
}

section{
	height: 100vh;
}


