@font-face {
    font-family: 'roboto-medium';
    src: url('/assets/fonts/roboto-medium-webfont.eot');
    src: url('/assets/fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/roboto-medium-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-medium-webfont.woff') format('woff'),
         url('/assets/fonts/roboto-medium-webfont.ttf') format('truetype'),
         url('/assets/fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto-regular';
    src: url('/assets/fonts/roboto-regular-webfont.eot');
    src: url('/assets/fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-regular-webfont.woff') format('woff'),
         url('/assets/fonts/roboto-regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto-bold';
    src: url('/assets/fonts/roboto-bold-webfont.eot');
    src: url('/assets/fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-bold-webfont.woff') format('woff'),
         url('/assets/fonts/roboto-bold-webfont.ttf') format('truetype'),
         url('/assets/fonts/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'roboto-light';
    src: url('/assets/fonts/roboto-light-webfont.eot');
    src: url('/assets/fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/roboto-light-webfont.woff2') format('woff2'),
         url('/assets/fonts/roboto-light-webfont.woff') format('woff'),
         url('/assets/fonts/roboto-light-webfont.ttf') format('truetype'),
         url('/assets/fonts/roboto-light-webfont.svg#robotolight') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'bernier-regular';
    src: url('/assets/fonts/bernierregular-regular-webfont.eot');
    src: url('/assets/fonts/bernierregular-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/bernierregular-regular-webfont.woff2') format('woff2'),
         url('/assets/fonts/bernierregular-regular-webfont.woff') format('woff'),
         url('/assets/fonts/bernierregular-regular-webfont.ttf') format('truetype'),
         url('/assets/fonts/bernierregular-regular-webfont.svg#bernier_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;

}